@import "./styles/_styles.scss";
@import './fontsLocal';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Italian Plate No2 Expanded';
}

* {
  box-sizing: border-box;
}
body.lock-scroll {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;

    margin-top: 0 !important;

    #branch-banner-iframe {
      display: none;
    }
}
body {
  transition: padding-top 0.1s ease-in-out;
  padding-top: var(--noti-height);
}
